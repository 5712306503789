<template>
  <floating-basket></floating-basket>
  <div class="bock1">
    <video class="video" playsinline autoplay muted>
      <source src="../public/img/videoback.mp4" type="video/mp4"/>
    </video>
    <video class="videoM" playsinline  autoplay muted>
      <source src="../public/img/videbackM.mp4" type="video/mp4"/>
    </video>
    <div class="content_overlay">
      <div class="header_block" id="overlay">
        <div class="nav">
          <p>ГЛАВНАЯ</p>
          <p>О НАС</p>
          <p>КОРЗИНА</p>
        </div>
      </div>
    </div>
  </div>

  <div class="bock2">
    <div class="up_block">
      <div class="titling">
        <p class="big_title">Шоколад</p>
        <p class="lit_title">Про шоколад коротко</p>
        <button @click="scrollToBlock('chklt')" class="bt2blk">Выбрать</button>
      </div>
      <div class="photo_block1"></div>
    </div>
    <div class="down_block">
      <div class="photo_block2"></div>
      <div class="titling2">
        <p class="big_title">Свечи</p>
        <p class="lit_title">Про свечи коротко</p>
        <button @click="scrollToBlock('svechi')" class="bt2blk">Выбрать</button>
      </div>
    </div>
  </div>

  <div class="bock3">
    <p class="assort_ttl">Ассортимент</p>
    <p id="chklt" class="rzdl">ШОКОЛАД</p>
    <div class="content_wrapper">
      <div class="content_container" ref="chocolateContainer">
        <productCard
            v-for="(card, index) in cardsChocolate"
            :key="card.category"
            :cardData="card"
            :class="{ 'active-card': index === currentIndexChocolate }"
        />
      </div>
      <button class="leftscroll" @click="scrollToBack('chocolate')"><img src="../public/img/leftarrow.svg"></button>
      <button class="rightscroll" @click="scrollToNext('chocolate')"><img src="../public/img/rightarrow.svg"></button>
    </div>

    <p id="svechi" class="rzdl">СВЕЧИ</p>
    <div class="content_wrapper">
      <div class="content_container" ref="svechiContainer">
        <productCard
            v-for="(card, index) in cardsSvechi"
            :key="card.category"
            :cardData="card"
            :class="{ 'active-card': index === currentIndexSvechi }"
        />
      </div>
      <button class="leftscroll" @click="scrollToBack('svechi')"><img src="../public/img/leftarrow.svg"></button>
      <button class="rightscroll" @click="scrollToNext('svechi')"><img src="../public/img/rightarrow.svg"></button>
    </div>
  </div>

  <div class="bock4">
    <p class="faq_title">FAQ</p>
    <div class="mob_cont">
      <p class="faq_subtitle">Ручное производство изделий из шоколада высочайшего качества</p>
    </div>

    <div class="inputs">
      <input class="thin_input" placeholder="Ваше имя">
      <input class="thin_input" placeholder="Номер телефона">
      <textarea class="fat_input" placeholder="Ваши пожелания"></textarea>
      <button class="send_button">ОТПРАВИТЬ</button>
    </div>
  </div>

  <div class="bock5">
    <div class="abt_ttl">
      <p style="font-size: 56px">О НАС</p>
      <p style="font-size: 24px">Какой-то текст о нас</p>
    </div>
    <div class="end_img"></div>
  </div>
</template>

<script>
import ProductCard from "@/components/productCard.vue";
import {CardAPI} from "@/api/CardAPI";
import FloatingBasket from "@/components/floatingBasket.vue";

export default {
  name: 'App',
  components: {
    FloatingBasket,
    ProductCard
  },
  data() {
    return {
      cardsChocolate: [],
      cardsSvechi: [],
      currentIndexChocolate: 0,
      currentIndexSvechi: 0,
      currentPage: 1
    };
  },
  async created() {
    try {
      const response = await CardAPI.getCard(this.currentPage, 100); // Делаем запрос к API
      this.cardsChocolate = response.cards.filter(card => card.category === 'Шоколад');
      this.cardsSvechi = response.cards.filter(card => card.category === 'Свечи'); // Сохраняем массив карточек
    } catch (error) {
      console.error("Ошибка при получении данных карточек:", error);
    }
  },
  methods: {
    scrollToNext(category) {
      let container, currentIndex, cards;

      if (category === 'chocolate') {
        container = this.$refs.chocolateContainer; // Получаем контейнер для шоколадных карточек
        currentIndex = this.currentIndexChocolate; // Текущий индекс для шоколадных карточек
        cards = this.cardsChocolate; // Массив карточек
      } else {
        container = this.$refs.svechiContainer; // Получаем контейнер для свечей
        currentIndex = this.currentIndexSvechi; // Текущий индекс для свечей
        cards = this.cardsSvechi; // Массив карточек
      }

      const cardWidth = container.children[0].offsetWidth; // Ширина первой карточки

      if (currentIndex < cards.length - 1) {
        // Если текущий индекс меньше длины массива карточек
        this[`currentIndex${category.charAt(0).toUpperCase() + category.slice(1)}`]++; // Увеличиваем индекс
        container.scrollBy({left: cardWidth, behavior: 'smooth'}); // Прокручиваем контейнер
      }
    },
    scrollToBack(category) {
      let container, currentIndex;

      if (category === 'chocolate') {
        container = this.$refs.chocolateContainer; // Получаем контейнер для шоколадных карточек
        currentIndex = this.currentIndexChocolate; // Текущий индекс для шоколадных карточек
      } else {
        container = this.$refs.svechiContainer; // Получаем контейнер для свечей
        currentIndex = this.currentIndexSvechi; // Текущий индекс для свечей
      }

      const cardWidth = container.children[0].offsetWidth; // Ширина первой карточки

      if (currentIndex > 0) {
        // Если текущий индекс больше 0
        this[`currentIndex${category.charAt(0).toUpperCase() + category.slice(1)}`]--; // Уменьшаем индекс
        container.scrollBy({left: -cardWidth, behavior: 'smooth'}); // Прокручиваем контейнер назад
      }
    },
    scrollToBlock(blockId) {
      const block = document.getElementById(blockId);
      if (block) {
        block.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
};
</script>
<style scoped>

@media (min-width: 769px) {
  .videoM{
    display: none;
  }
  .leftscroll {
    display: none;
  }

  .rightscroll {
    display: none;
  }

  .faq_subtitle {
    font-size: 24px;
    color: #717171;
    margin-left: 160px;
    width: 426px;
    margin-top: 24px
  }

  .faq_title {
    font-size: 56px;
    color: #717171;
    margin-left: 160px
  }

  .content_overlay {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
  }

  .content_container {
    width: 77.7vw;
    margin-left: 160px;
    margin-right: 160px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .subwrap {
    display: flex;
  }

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw; /* Ширина видео по ширине экрана */
    height: 100vh; /* Высота видео по высоте экрана */
    object-fit: cover; /* Масштабирование видео для заполнения контейнера */
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .bock1 {
    position: relative;
    width: 100%;
    height: 100vh; /* Высота контейнера по высоте экрана */
    overflow: hidden;
  }

  .header_block {
    margin-left: 60px;
    margin-right: 60px;
    display: flex;
    justify-content: flex-end;
  }

  .name_title {
    margin-top: 155px;
    letter-spacing: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .naming {
    font-size: 72px;
  }

  .nav {
    margin-top: 60px;
    font-size: 24px;
    width: 33vw;
    display: flex;
    justify-content: space-between;
  }

  .logo {
    width: 210px;
    height: 210px;
    background: black;
  }


  .bock2 {
    background-image: url("../public/img/gradien_block3.png");
    background-size: cover;
    width: 100%;
    height: 100vh;
  }

  .down_block {
    height: 50vh;
    display: flex;
    justify-content: space-between;
  }

  .up_block {
    height: 50vh;
    display: flex;
    justify-content: space-between;
  }

  .bt2blk {
    border: none;
    border-radius: 100px;
    margin-top: 15px;
    width: 100%;
    height: 30px;
    font-size: 20px;
  }

  .big_title {
    font-size: 56px;
    color: #717171;
  }

  .lit_title {
    margin-left: 4px;
    margin-top: -5px;
    color: white;
    font-size: 18px;
  }

  .titling {
    width: 11vw;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .titling2 {
    width: 11vw;
    margin-right: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .photo_block2 {
    background: url("../public/img/block_svechi.png");
    background-size: cover;
    width: 50%;
  }

  .photo_block1 {
    background: url("../public/img/block_choco.png");
    background-size: cover;
    width: 50%;
  }


  .bock3 {
    padding-bottom: 80px;
    height: fit-content;
    padding-top: 160px;
    width: 100%;
    background-image: url("../public/img/gradien_block3.png");
    background-size: cover;
  }

  .assort_ttl {
    margin-left: 160px;
    font-size: 56px;
    color: #717171;
  }

  .rzdl {
    margin-top: 36px;
    margin-left: 160px;
    font-size: 48px;
    color: white;
  }

  .content_container {
    margin-top: 50px;
    height: fit-content;
  }


  .bock4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding-top: 70px;
    width: 100%;
    background-image: url("../public/img/gradien_block3.png");
    background-size: cover;
  }

  .inputs {
    margin-top: 26px;
    margin-left: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .thin_input {
    font-size: 14px;
    padding-left: 24px;
    height: 50px;
    margin-top: 16px;
    width: 581px;
    border: none;
    border-radius: 10px;
  }

  .fat_input {
    resize: none;
    padding-top: 16px;
    font-size: 14px;
    padding-left: 24px;
    height: 110px;
    margin-top: 16px;
    width: 581px;
    border: none;
    border-radius: 10px;
  }

  .send_button {
    margin-top: 30px;
    height: 50px;
    width: 260px;
    background-color: transparent;
    border: white solid 1px;
    border-radius: 10px;
    color: white;
  }


  .bock5 {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    background-image: url("../public/img/gradien_block3.png");
    background-size: cover;
  }

  .end_img {
    height: 100%;
    width: 70.1vw;
    background-color: black;
  }

  .abt_ttl {
    width: 29.8vw;
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
  }

  .scroll-button {
    display: none;
  }
}

@media (max-width: 768px) {
 .videoM{
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100vw;
   height: 100vh;
   -o-object-fit: cover;
   object-fit: cover;
   transform: translate(-50%, -50%);
   z-index: -1;
 }
  .video {
    display: none;
  }

  .bock1 {
    position: relative;
    width: 100%;
    height: 100vh; /* Высота контейнера по высоте экрана */
    overflow: hidden;
  }

  .content_overlay {
    display: none;
  }

  .bock2 {
    height: 100vh;
    background-image: url("../public/img/gradien_block3.png");
    background-size: cover;
  }

  .up_block {
    background: url("../public/img/block_choco.png");
    height: 50vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .down_block {
    background: url("../public/img/block_svechi.png");
    height: 50vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titling {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .titling2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .big_title {
    font-weight: bold;
    font-size: 56px;
    color: white;
  }

  .lit_title {
    margin-top: 30px;
    font-size: 24px;
    color: white;
  }

  .bt2blk {
    border-radius: 30px;
    border: none;
    margin-top: 14px;
    width: 70.7vw;
    height: 48px;
    font-size: 24px;
  }

  .bock3 {
    padding-top: 75px;
    width: 100vw;
    height: 100%;
    background-image: url("../public/img/gradien_block3.png");
  }

  .assort_ttl {
    color: #717171;
    font-size: 42px;
    display: flex;
    justify-content: center;
  }

  .content_wrapper {
    position: relative;
  }

  .content_container {
    margin-top: 10px;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
  }

  .rzdl {
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    font-size: 32px;

  }

  .content_container::-webkit-scrollbar {
    display: none; /* Скрываем скроллбар */
  }

  .leftscroll {
    position: absolute;
    height: 100%;
    top: 0;
    background-color: transparent;
    border: none;
    z-index: 1003;
  }

  .rightscroll {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    z-index: 1003;
  }

  .bock4 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 40px;
    width: 100%;
    background-image: url("../public/img/gradien_block3.png");
    background-size: cover;
  }

  .inputs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .thin_input {
    font-size: 14px;
    padding-left: 24px;
    height: 50px;
    margin-top: 16px;
    width: 80vw;
    border: none;
    border-radius: 10px;
  }

  .fat_input {
    resize: none;
    padding-top: 16px;
    font-size: 14px;
    padding-left: 24px;
    height: 110px;
    margin-top: 16px;
    width: 80vw;
    border: none;
    border-radius: 10px;
  }

  .send_button {
    margin-top: 30px;
    height: 50px;
    width: 260px;
    background-color: transparent;
    border: white solid 1px;
    border-radius: 10px;
    color: white;
  }

  .faq_title {
    font-size: 56px;
    color: #717171;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .faq_subtitle {
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 70.9vw;
    margin-top: 30px;
  }

  .mob_cont {
    display: flex;
    justify-content: center;
  }

  .bock5 {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    background-image: url("../public/img/gradien_block3.png");
    background-size: cover;
  }

  .abt_ttl {
    width: 100vw;
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
  }
}


</style>
