<script>

export default {
  name: "productCard",
  props: {
    cardData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      imageName: ""
    }

  },
  methods: {
    getImageUrl(imageName) {
      const baseUrl = 'https://s3.timeweb.cloud/30489bee-storelanding/';
      return `${baseUrl}${imageName}`;
    },
  }
}
</script>

<template>
  <div class="wrapper">
    <img :src="getImageUrl(cardData.image)">
    <p style="font-size: 30px; margin-top: 25px">{{ cardData.label }}</p>
    <p style="font-size: 16px; margin-top: 10px">{{ cardData.price }}</p>
    <button>ДОБАВИТЬ К ЗАКАЗУ</button>
  </div>

</template>

<style scoped>
@media (min-width: 769px) {
  .spacer{
    width: 235px;
    height: 10px;
  }
  .wrapper {
    margin-bottom: 50px;
  }

  .wrapper img {
    border-radius: 10px;
    width: 450px;
    height: 450px;
  }

  .wrapper p {
    max-width: 450px;
    color: white;
  }

  .wrapper button {
    margin-top: 30px;
    height: 50px;
    width: 18.2vw;
    min-width: 262px;
    color: white;
    border: solid 1px white;
    border-radius: 10px;
    background-color: transparent;
  }
}

@media (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    flex-shrink: 0;
    margin-bottom: 50px;
  }

  .wrapper img {
    border-radius: 10px;
    width: 248px;
    height: 248px;
  }

  .wrapper p {
    color: white;
  }

  .wrapper button {
    margin-top: 30px;
    height: 50px;
    width: 140px;
    color: white;
    border: solid 1px white;
    border-radius: 10px;
    background-color: transparent;
  }
}
</style>
