<script>
export default {
  name: 'floatingBasket',
}
</script>

<template>
<div class="container_cart"><img src="../../public/img/shopping_cart.svg"></div>
</template>

<style scoped>
@media (min-width: 769px) {
  .container_cart{
    position: fixed;
    background-color: white;
    border-radius: 150px;
    bottom: 50px;
    right: 50px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container_cart img{
    width: 50px;
  }
}
@media (max-width: 768px) {


  .container_cart {
    z-index: 100000;
    position: fixed;
    background-color: white;
    border-radius: 150px;
    bottom: 20px;
    right: 20px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container_cart img {
    width: 50px;
  }
}
</style>